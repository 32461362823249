import React from 'react';
import './App.css';

import { Router } from './Routes/Routes';


const App = () => {
  return (
    <div className="App">
      <Router />
    </div>
  );

};

export default App;
