export const cb1 = '  await setTimeout(() => console.log(\'Hello\'), 1000);';
export const cb2 = `  await setState({
    message: 'Hello'
  });`;
export const cb3 = ` // 1)
  let a = [1];
  let b = [1];
  console.log(a === b) // what does this log? 

  // 2)
  let d = parseInt('hello');
  console.log(typeof d); // what does this log? Number
  console.log(d === d) // what does this log?

  // 3)
  function test1() {
        let a = 1;
        console.log(a) // what does this log?
        setTimeout(() => {a = 2}, 1000);
        console.log(a) // what does this log?
        setTimeout(() => {a = 3}, 0); 
        console.log(a) // what does this log?
  }
  test1();

  // 4)
  function test2() {
      for (var i = 0; i < 5; i++) {
          setTimeout(() => console.log(i), 0);
      }
  }
  test2(); // what does this log?

  // 5)
  function test3() {
      for (var i = 0; i < 5; i++) {
          ((i) => {
              setTimeout(() => console.log(i), 0);
          })(i);
      }
  }
  test3(); // what does this log?
  
  // 6)
  let num = 10;
  let obj1 = {item: "unchanged"};
  let obj2 = {item: "unchanged"};

  function changeStuff(a, b, c) {
      a = a * 10;
      b.item = "changed";
      c = {item: "changed"};
  }
  changeStuff(num, obj1, obj2);

  // what will these log?
  console.log(num);
  console.log(obj1.item);
  console.log(obj2.item);

  // 7)
  function test4() {
      a = 10;
      let b = 50;
  }
  test4();
  console.log(a);
  console.log(b);

  /* Write a function 'createBase' that would allow you to do this: */
  const addNine = createBase(9);
  console.log(addNine(10)) // 19
  console.log(addNine(21)) // 30


  /* Write a function that checks whether two strings are anagrams */`;

export const cb4 = `  1. Javascript Scoping
  2. The Event Loop
  3. Prototypical Inheritance 
  4. Asynchronous Javascript
  5. Pass by Value vs Pass by Reference
  6. ES6 Concepts/Syntax
  7. Hoisting`;

export const cb5 = `1) It maintains React's predictable lifecycle methods
2) It allows the developer to choose what state is maintained and what state is not`;

