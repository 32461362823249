import React from 'react';
import { Link, Outlet } from 'react-router-dom';

import Header from '../Header/Header';
import './BlogMain.css';


function BlogMain() {

  return (
    <div className='blogmain-wrapper'>
      <Header
        label='Blog.'
        isHome={ false }
      />
      <Link
        to='the_problem_with_react_navigation_5'
        className='link'
      >
        <h2 className='h-link'>The Problem with React Navigation 5</h2>
      </Link>
      <Link
        to='reactjs_comprehensive_primer'
        className='link'
      >
        <h2 className='h-link'>Essential React Learning Path</h2>
      </Link>
      <Outlet />
    </div>
  );
}

export default BlogMain;
