import {
  createOvermind,
  IContext
} from 'overmind';
import {
  createActionsHook, createStateHook
} from 'overmind-react';
import { namespaced } from 'overmind/config';

import * as theme from './theme';


export const config = namespaced({
  theme,
});

export type Context = IContext<{
  state: typeof config.state;
  actions: typeof config.actions;
  effects: typeof config.effects;
}>;

export const overmind = createOvermind(config);
export const useAppState = createStateHook<Context>();
export const useActions = createActionsHook<Context>();
