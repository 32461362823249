import React from 'react';
import {
  BrowserRouter,
  Route, Routes
} from 'react-router-dom';
import './Routes.css';

import BlogMain from '../components/BlogPage/BlogMain';
import CoverPage from '../components/CoverPage/CoverPage';
import Post1 from '../components/BlogPage/BlogPosts/Post1';
import Post2 from '../components/BlogPage/BlogPosts/Post2';


export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={ <CoverPage /> }
        />
        <Route
          path='blog'
          element={ <BlogMain /> }
        />
        <Route
          path='blog/the_problem_with_react_navigation_5'
          element={ <Post1 /> }
        />
        <Route
          path='blog/reactjs_comprehensive_primer'
          element={ <Post2 /> }
        />
      </Routes>
    </BrowserRouter>
  );
};

