import React from 'react';
import {
  CodeBlock, dracula, github
} from 'react-code-blocks';

import Header from '../../Header/Header';
import {
  cb1, cb2, cb3, cb4
} from '../../../resources/codeBlocks';


function Post2() {
  const cbFontSize = '1.3em';

  return (
    <div style={{
      width: '100%',
    }}
    >
      <Header
        label="Essential React Learning Path"
        smallFont={ true }
      />
      <h2>What is this guide?</h2>
      <p>
        A curated study guide for those new to React.js and React Native. All
        the listed resources are completely free including the interactive
        Scrimba course. Scrimba does offer a paid version that goes in depth
        into functional components and hooks. This is a fantastic course but
        once the developer gets through the free course, the developer will have
        all the tools needed to learn everything else React has to offer from
        the
        {' '}
        <a
          href="https://reactjs.org/docs/hooks-intro.html"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          documentation
        </a>
        .
      </p>
      <h2>Requirements</h2>
      <p>
        Before going through this guide, the developer should have solid
        programming fundamentals on which to build upon. A working understanding
        of javascript is essential to learning React and the developer should
        aim for complete mastery over the javascript programming language.
        {' '}
      </p>
      <p>
        <b>Before moving forward be sure you have a solid understanding of:</b>
      </p>
      <CodeBlock
        text={ cb4 }
        language={ 'text' }
        theme={ github }
        showLineNumbers={ false }
        customStyle={{
          fontSize: '1.4em',
        }}
      />
      <h2>Essential Javascript</h2>
      <p>
        Because Javascript comprehension is so crucial to writing and navigating
        your way through React (and any JS framework), we will start here.
      </p>
      <p>
        {'Even if the developer considers him/herself an expert of Javascript, many veteran JS developers still are unable to properly describe concepts such as the Event Loop, the Callstack, & Job Queue. This leads many such developers to adopt silly heuristics and commonly held opinions such as "avoid functions such as setTimeout because it\'s bad". A good grasp of the Event Loop and how asynchronous javascript works will reveal how silly this belief is. Javascript is at the current moment the fastest growing language among new developers (ie people who are new to programming concepts in general). Because of this, silly commonly held beliefs are even more dangerous as more and more programs are becoming \'javascriptified\'. Javascript also gets a bad rap by the computer science community and the \'serious developers\' because of how much bad javascript code exists in the world.'}
      </p>
      <p>Your goal is to not be one of these developers :)</p>
      <p>
        <b>
          {'Here\'s the gist:'}
        </b>
        {' '}
        If you do not understand the event loop, scope
        and closures, callbacks and async,
        <b>YOU DO NOT KNOW JAVASCRIPT</b>
        .
        {'This isn\'t an elitist standpoint or trivia knowledge. These concepts are fundamental to writing, debugging, troubleshooting, and solving nuance problems in this language.'}
      </p>
      <p>
        {' '}
        {'It is possible to write React for a little while without this knowledge, just like it\'s possible to drive a car without knowing what is under the hood, or that there is a hood, or that wheels are round. A monkey can be trained to drive a car, just like it can be trained to copy and paste React code. But most developing jobs are not akin to driving a great car on a straight and narrow road (to continue the analogy). It\'s more akin to driving a broken down car along a bumpy dirt road. It may be okay for a little while, but at the first flat tire, you\'ll be stranded. No, you don\'t need to know how to build a radiator or rebuild a transmission just like you don\'t need to know assembly or machine code. But you do need to fully understand the layer of abstraction just below the current layer you\'re aiming for. If you\'re aiming to master React, you also need to fully comprehend javascript.'}
      </p>
      <p>
        {'For example, if you don\'t know what\'s wrong with this code in javascript...'}
      </p>
      <CodeBlock
        text={ cb1 }
        language={ 'javascript' }
        theme={ dracula }
        customStyle={{
          fontSize: cbFontSize,
        }}
      />
      <p>
        {'Then you won\'t understand what\'s wrong with this code in React:'}
      </p>
      <CodeBlock
        text={ cb2 }
        language={ 'javascript' }
        theme={ dracula }
        customStyle={{
          fontSize: cbFontSize,
        }}
      />
      <p>
        <b>
          {'If you don\'t know the answers to the following off the top of your head, please please please go through the javascript guide first, for your own benefit and the benefit of your fellow programmers.  '}
        </b>
      </p>
      <CodeBlock
        text={ cb3 }
        language={ 'javascript' }
        theme={ dracula }
        customStyle={{
          fontSize: cbFontSize,
        }}
      />
      <p>
        {'If you\'re a new javascript developer, take the time to go through this guide diligently. If you\'re a veteran, go through this guide briefly and see if you can verbally explain everything that\'s going on.  '}
      </p>
      <h2>Javascript Guide: </h2>
      <p>
        1.
        {' '}
        <a
          href="https://www.crockford.com/code.html"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Code Conventions
        </a>
      </p>
      <p>
        2.
        {' '}
        <a
          href="http://www.youtube.com/watch?v=8aGhZQkoFbQ"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Event Loop
        </a>
      </p>
      <p>
        3.
        {' '}
        <a
          href="http://www.youtube.com/watch?v=Bv_5Zv5c-Ts&t=1878s"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Weird Parts
        </a>
      </p>
      <p>
        4.
        {' '}
        <a
          href="http://youtu.be/viQz4nUUnpw"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Callbacks & Higher Order Functions
        </a>
      </p>
      <p>
        5.
        {' '}
        <a
          href="http://github.com/getify/You-Dont-Know-JS/tree/2nd-ed/scope-closures"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'You Don\'t Know JS Yet: Scope & Closures'}
        </a>
      </p>
      <p>
        6.
        {' '}
        <a
          href="https://en.wikipedia.org/wiki/Evaluation_strategy#Call_by_sharing"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Call By Sharing
        </a>
      </p>
      <p>
        7.
        {' '}
        <a
          href="http://www.tutorialsteacher.com/online-test/javascript-test"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Concept Quiz
        </a>
      </p>
      <p>
        It is
        {' '}
        <b>Essential</b>
        {' '}
        to have a strong foundation of JS and vanilla web
        developing principles (HTML/CSS/JS) before starting this journey. But a
        common sentiment among React developers is that learning and writing
        React has made them better Javascript developers and vice versa.
      </p>
      <h2>React Guide</h2>
      <p>
        {'Go in order, at your own pace. React concepts can be difficult to grasp at first. But once a new React developer "breaks through" and has the light bulb moment, it can be an incredibly rewarding and enjoyable experience to think and code in the React component paradigm.  '}
      </p>
      <p>
        {' God speed! See you on the other side :)'}
      </p>
      <p>
        1.
        {' '}
        <a
          href="http://medium.com/javascript-scene/the-missing-introduction-to-react-62837cb2fd76"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Missing Introduction to React
        </a>
      </p>
      <p>
        2.
        {' '}
        <a
          href="http://https://blog.glitch.com/post/react-starter-kit"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          A Brief Intro
        </a>
      </p>
      <p>
        3.
        {' '}
        <a
          href="http://https://scrimba.com/learn/learnreact"
          className="blog-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Tutorial That Finally Gave Me The Breakthrough
        </a>
      </p>
      <p />
      <h2>For List Lovers</h2>
      <p>
        Ideal and most efficient track coming from a guy who struggled a lot
        with fully grokking React:
      </p>
      <p>1. Understand how vanilla web works in principle (HTML/CSS/JS)</p>
      <p>
        {'2. Full mastery over Javascript (too many will skip this step at their demise, and most don\'t know how to gauge this)'}
      </p>
      <p>
        3. A couple of days of React fundamentals, the virtual DOM, class based
        components and life cycle events/methods (internalize this stuff to
        forget this stuff)
      </p>
      <p>4. Functional React and the most common hooks</p>
      <p>
        5. Greenfielding a project and understanding best practices for project
        layout and file structure. Best approach is to pull many projects from
        major projects. There is no React standard but some structures will be
        more intuitive to you than others.
      </p>
      <p>
        6. Learning how to think about creating components. What should be a
        standalone component, what state should it have, what state is it being
        passed and is passing, separating business logic from render views. I
        remember this was the most daunting part of using React when I first
        started. This step will make or break the readability and
        maintainability of your codebase as your code develops. Sounds easy, but
        greenfield a couple of projects and it becomes clear that this is a
        skill in the strictest sense, not something cerebral.
        {' '}
      </p>
      <p>
        {' 7. Learning the industry standard packages. The reason React is not considered a full fledge framework, unlike Angular, is because it does not come with everything you will need for most applications. React is an environment and a community. You\'ll need Routing/Navigation and Global State management as the two primary outside packages.\n'}
      </p>
      {' '}
      <p>8. React Native, Next, Gatsby</p>
    </div>
  );
}

export default Post2;
