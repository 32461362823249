import React, { useCallback } from 'react';
import { AiFillHeart } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

import { useActions, useAppState } from '../../state';
import './Header.css';


type Props = {
  label: string;
  smallFont?: boolean;
  isHome?: boolean;
}

function Header({ label, isHome = false, smallFont = false }: Props) {
  const themeActions = useActions().theme;
  const themeState = useAppState().theme;
  const navigate = useNavigate();

  const onThemeClick: React.MouseEventHandler<SVGElement> = useCallback((e) => {
    e.preventDefault();
    const isDark = themeState.type === 'dark';
    themeActions.setTheme(isDark ? 'light' : 'dark');
  }, [themeActions, themeState]);

  const onClickBack: React.MouseEventHandler<SVGElement> = useCallback((e) => {
    e.preventDefault();
    navigate(-1);
  }, [navigate]);

  return (
    <div className="header-wrapper">
      <h1
        className='header'
        style={{
          fontSize: smallFont ? '3em' : '5em',
          display: 'inline-block',
        }}
      >
        {label}
      </h1>
      <div className="header-right">
        <AiFillHeart
          className='nav-icon'
          size={ 20 }
          onClick={ onThemeClick }
        />
        <IoIosArrowBack
          className='nav-icon'
          size={ 30 }
          style={{
            visibility: isHome ? 'hidden' : 'visible',
          }}
          onClick={ onClickBack }
        />
      </div>
    </div>
  );
}

export default Header;
