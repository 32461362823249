import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'overmind-react';

import App from './App';
import { overmind } from './state';


function OvermindWrapper() {
  return (
    <Provider value={ overmind }>
      <App />
    </Provider>
  );
}

ReactDOM.render(<OvermindWrapper />, document.getElementById('root'));
