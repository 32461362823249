import React from 'react';
import { CodeBlock, github } from 'react-code-blocks';

import Header from '../../Header/Header';
import { cb5 } from '../../../resources/codeBlocks';


function Post1() {

  return (
    <div className='post1-wrapper'>
      <Header
        label='The Problem With React Navigation 5'
        smallFont={ true }
      />
      <h2>The Story</h2>
      <p>Going from React Web to React Native is weird.</p>
      <p>
        {'Conditionally rendering html with javascript on the web is pretty straightforward. Instead of making separate GET requests to a server upon hitting a url address, we simply react to the route itself and choose what to render. React Router abstracts the conditional rendering while maintaining how we expect React Components to behave; maintaining their lifecycle predictably.'}
      </p>
      <p>
        {'I\'ve taken for granted how many api\'s are given to us natively in all browsers. The URL bar, back and forward arrows, and browser history are all built in. In React Native we have none of these features built in. Which means the developer has full control of the user experience and full control of the flow of the application. In React Native there really is no such thing as a \'route\', but rather the user '}
        <i>
          {'navigates\n'}
        </i>
        through an app. And the defacto navigation package for the past half decade has been React Navigation.
      </p>
      <h2>How React Navigation Works</h2>
      <p>
        The main component of React Navigation is the
        <i>Stack Navigator</i>
        {'. It acts much like React Router\'s Switch and Link components. The developer can navigate to any component within a Stack Navigator from any component within the stack. Nested stacks work similarly except that the props passed from each Stack are scoped. The docs explain a Stack Navigator much like an actual stack data structure where navigating to another component \'pushes\' that component onto the stack and navigating back from that component \'pops\' the previous component off the stack. From a component lifecycle standpoint, this is not at all what is happening. What really happens is that when you navigate to another component, that new component is mounted. Navigating back the original component just conditionally renders the previous component. And herein lies the biggest problem with React Navigation:'}
        <b>no component is ever unmounted after mounting in a Stack Navigator.</b>
      </p>
      <h2>
        {'OK... So What\'s The Big Deal? '}
      </h2>
      <p>
        {'"This isn\'t the web, mobile users have different expectations for app behavior than the web experience."'}
        <br />
        {'Fair enough. And the React Navigation team even references the mobile user experience as the primary motivation behind this behavior. But what are the consequences of not unmounting components like this?\n'}
      </p>
      <p>
        {'Well first and foremost, it allows all component state in a Stack Navigator to be maintained. In a multi-component form, the user expects the previous form screen to maintain the inputted data. It would totally suck if the previous form screen\'s data was reset. Cool, this is the user expectation on this platform.'}
      </p>
      <p>
        What else does this mean? Well it means that React Hooks like
        <i>useEffect</i>
        {' '}
        and
        <i>useState</i>
        {' '}
        {'do not work as a React Developer expects them to. When a component is rendered, we expect that this component is mounted. When we conditionally render away from this component, we expect this component to unmount. This is not a React Web versus React Native paradigm. This is a React paradigm.'}
      </p>
      <p>
        In a Stack Navigator, calling
        <i>useEffect</i>
        {' '}
        {'with an empty array as a second argument (or with any static variable) means that the function will only run once upon the first instance of navigating to this component. Because the component never unmounts,'}
        <i>useEffect</i>
        {' '}
        will never run again. To this end, React Navigation provides a custom hook called
        <i>useFocusEffect</i>
        {' '}
        that functions like
        <i>useEffect</i>
        {' '}
        {'except running when the component is in focus instead of on mount. But to use this hook you must wrap your effect function with React\'s'}
        <i>useCallback</i>
        {' '}
        {'which causes issues with closures! (we\'ll talk about this soon).'}
      </p>
      <p>
        {'It\'s really important to know that the Stack Navigator does not maintain state globally. It maintains all the component state by literally not unmounting them. And React Navigation does not give the developer the option to unmount components. It forces changes in core React lifecycle expectations on the developer and insisting it\'s for the good of the user.'}
        <b>But is this base assumption actually true? Would the user experience suffer otherwise?</b>
      </p>
      <h2>How Could We Solve The Mobile Experience Problem Using React Paradigms?</h2>
      <p>
        {'Actually, React web developers deal with this problem all of the time. It\'s called global state.'}
        <br />
        {'Okay, so we need to maintain a previous component\'s state after it leaves the view. Perfect, React developers know how to handle this. Just define what you need as global state instead of local. We don\'t even have to define this state at the root of the application, just at the Stack Navigator component.'}
        Now, we, as the developers, get to
        {' '}
        <i>choose</i>
        {' '}
        {'which state we want maintained and which state we would like to keep local. React Navigation could abstract this state management away with the context api and give the developer the choice of what state is maintained with, perhaps, a custom hook called'}
        {' '}
        <i>useStackState</i>
        . Creating a hook like this would nullify the need for
        {' '}
        <i>useFocusEffect</i>
        {' '}
        completely! The main benefits of implementing Stack Navigator in this manner is as follows:
      </p>
      <CodeBlock
        text={ cb5 }
        language={ 'text' }
        theme={ github }
        showLineNumbers={ false }
        customStyle={{
          fontSize: '1.6em',
        }}
      />
      <p />
    </div>
  );
}

export default Post1;

