import React from 'react';
import '../CoverPage/CoverPage.css';
import {
  Link, Outlet
} from 'react-router-dom';

// import Resume from '../../resources/Resume2022.pdf';
import Header from '../Header/Header';


function CoverPage() {
  return (
    <div className="cover-page-wrapper">
      <Header
        label='Hello.'
        isHome={ true }
      />
      <h2>My name is John Kangsumrith.</h2>
      <p>
        {'I\'m a staff software developer @'}
        <a
          className='ext-link'
          href="https://www.nextgenhq.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          NextGenHQ
        </a>
        {' and prior chemical engineer @'}
        <a
          className='ext-link'
          href='https://www.lenzing.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Lenzing
        </a>
        {'. I build React Native Mobile apps, and I\'m a pretty fervid '}
        <a
          href="https://www.google.com/search?q=rustacean&oq=rustacian&aqs=chrome.1.69i57j0i10l6.5895j0j9&sourceid=chrome&ie=UTF-8"
          target='_blank'
          rel='noopener noreferrer'
          className="ext-link"
        >
          Rustacean
        </a>
        <span
          role="img"
          aria-label="crab"
        >
          🦀.
        </span>
      </p>
      <p>
        {'Check out my '}
        <Link
          to='blog'
          style={{
            textDecoration: 'none',
          }}
        >
          <span className='ext-link'>blog</span>
        </Link>
        {` where I deep dive into React, React Native, Typescript, and a whole lot of other topics. 
                Or find me contributing to Open Source libraries I'm fond of 
                on `}
        <a
          className='ext-link'
          href="https://github.com/laffed"
          target="_blank"
          rel="noopener noreferrer"
        >
          GitHub
        </a>
        {'.'}
      </p>
      {/* You've found my hidden resume! */}
      {/* <p>
        {'Looking for a Software Engineer? Check out my '}
        <a
          className='ext-link'
          href={ Resume }
          target="_blank"
          rel="noopener noreferrer"
        >
          resume
        </a>
        {' to see if we\'d make a good fit.'}
      </p> */}
      <p>
        {'And if you just want to learn more, check out my '}
        {' '}
        <br />
        <a
          className='ext-link'
          href="https://www.youtube.com/channel/UCMrerTq_nchK9JcMJSYYlzw/videos"
          target="_blank"
          rel="noopener noreferrer"
        >
          YouTube Channel
        </a>
        {' where I tackle algorithms and other computer science topics.'}

      </p>
      <p>
        Outside of programming, I dabble in jazz guitar/keyboard, writing, and kiteboarding.
      </p>
      <Outlet />
    </div>
  );
}


export default CoverPage;


